<template>
    <b-card title="Data Test IST 📃">
    <b-form @submit.prevent>
        <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Nama Test IST"
            label-for="v-nama-test-ist"
          >
            <b-form-input
              id="v-nama-test-ist"
              v-model="data.nama_test"
              placeholder="Nama Test IST"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Kode Test IST"
            label-for="v-kode-test-ist"
          >
            <b-form-input
              id="v-kode-test-ist"
              v-model="data.kode_test"
              placeholder="Kode Test IST"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Waktu Test IST (Menit)"
            label-for="v-waktu-test-ist"
          >
            <b-form-input
              id="v-waktu-test-ist"
              v-model="data.waktu"
              placeholder="Waktu Test (Menit)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Cara Pengerjaan"
            label-for="v-cara-pengerjaan"
          >
            <b-form-input
              id="v-cara-pengerjaan"
              v-model="data.cara_pengerjaan"
              placeholder="Cara Pengerjaan Test"
            />
          </b-form-group>
        </b-col>
        <b-col>
              <b-form-group
                  label="Hasil Test"
                  label-for="v-hasil-test"
              >
              <quill-editor
                  id="hasil_test"
                  rows="4"
                  v-model="data.hasil_test"
              />
            </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveData()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'

const Block = Quill.import('blots/block')
Block.tagName = 'span'
Quill.register(Block)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    quillEditor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        nama_test: '',
        kode_test: '',
        waktu: '',
        cara_pengerjaan: '',
        hasil_test: '',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`/ist-test/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.data = response.data.data
        }
      })
    },
    saveData() {
      const data = {
        nama_test: this.data.nama_test,
        kode_test: this.data.kode_test,
        waktu: this.data.waktu,
        cara_pengerjaan: this.data.cara_pengerjaan,
        hasil_test: this.data.hasil_test,
      }
      this.$http.put(`/update-ist-test/${this.$route.params.id}`, data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'ist-index' })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
